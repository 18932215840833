html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

/* canvas {
  width: 100%;
  height: 700px;
} */

.construktor {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 50px; /* Опционально, добавляет промежуток между колонками */
  height: 100%; /* Задайте высоту по вашему усмотрению */
}

.controls-content {
  overflow-y: auto;
  max-height: 90vh;
}